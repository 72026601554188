body {
  overflow: visible;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.gr-scroller {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color, transparent) transparent;
}

.gr-scroller:not(.scrolled)::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, black);
  border-radius: 10px;
  transition: transform 0.3s;
}

.gr-scroller.scrolled::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, #e30e0e);
  border-radius: 10px;
  transition: transform 0.3s;
}

.gr-scroller:hover:not(.scrolled)::-webkit-scrollbar-thumb {
  transform: scale(1.2);
}

.gr-scroller::-webkit-scrollbar {
  width: 10.69px;
  border-radius: 10px;
}

.gr-scroller::-webkit-scrollbar-track {
  background: transparent;
}

.gr-scroller.thumb-hovered::-webkit-scrollbar-thumb {
  transition: background 0.5s;
}

.gr-scroller::-webkit-scrollbar-thumb.end-scroll {
  background: var(--scrollbar-thumb-color-end, #e30e0e);
}
