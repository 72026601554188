@font-face {
  font-family: 'GR';
  src: local('GR'), url('../HUDFonts/TextMeOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ModeNine';
  src: local('ModeNine'), url('../HUDFonts/MODENINE.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}