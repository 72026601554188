.hud-input-light::placeholder {
  color : #d1d1d1;
}

.hud-input-light {
  overflow-y : scroll;
  scrollbar-width : thin;
  scrollbar-color : var(--scrollbar-thumb-color, transparent) transparent;
}

.hud-input-light::-webkit-scrollbar {
  display : none;
}

.hud-input-light::-webkit-scrollbar-track {
  background : transparent;
}

.hud-input-light:not(.scrolled)::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, transparent);
  border-radius: 10px;
  transition: transform 0.3s;
}

.hud-input-light.scrolled::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, transparent);
  border-radius: 10px;
  transition: transform 0.3s;
}

.hud-input-light.thumb-hovered::-webkit-scrollbar-thumb {
  transition: background 0.5s;
}

.hud-input-light::-webkit-scrollbar-thumb.end-scroll {
  background: var(--scrollbar-thumb-color-end, transparent);
}